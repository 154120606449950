import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ITrack } from 'interfaces';
import { API_URL } from 'constants/api';
import { getTurnstileToken } from 'utils/turnstile';
import { addNotification  } from '../notifications/actions';

type GenerateTrackParams = {
  menuChoice?: string;
  playlist?: string;
  duration: number;
  sessionId?: string;
  trackType?: 'track' | 'jingle' | 'loop' | 'mix'
  text?: string;
  url?: string;
  token: string;
};

type GeneratedTracksResponse = {
  pages_count: number;
  tracks_count: number;
  tracks: ITrack[];
};

export const trackListApi = createApi({
  reducerPath: 'generatedTracks',
  tagTypes: ['Track'],
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getGeneratedTrackList: builder.query<GeneratedTracksResponse, {page: number}>({
      query: ({page}) => ({
        url: 'TrackList',
        params: {
          page
        }
      }),
      transformResponse: (result: any) => result?.data,
      providesTags: ['Track']
    }),
    removeTrackById: builder.mutation<void, string>({
      query: (sessionId) => ({
        url: `TrackDelete`,
        method: 'POST',
        body: {
          params: {
            session_id: sessionId
          }
        }
      }),
      invalidatesTags: ['Track']
    }),
    editGeneratedTrackName: builder.mutation<any, {sessionId: string, name: string, page: number}>({
      query: ({sessionId, name}) => ({
        url: `TrackEdit`,
        method: 'POST',
        body: {
          method: 'TrackEdit',
          params: {
            session_id: sessionId,
            name
          }
        }
      }),
      async onQueryStarted({ sessionId, name, page }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedTrack } = await queryFulfilled
          
          if (updatedTrack.data?.code !== 1) {
            dispatch(addNotification({
              title: 'Unknown error happened, please try again',
            }));
          } else {
            dispatch(
              trackListApi.util.updateQueryData('getGeneratedTrackList', { page }, (draft) => {
                draft.tracks = draft.tracks.map(track => {
                  if (track.session_id === sessionId) {
                    track.name = name
                  }
                  return track
                })
              })
            )
          }
        } catch (e) {
          dispatch(addNotification({
            title: 'Unknown error happened, please try again',
          }));
          console.log(e);
        }
      },
    }),
    createTrack: builder.mutation<any, GenerateTrackParams>({
      query: ({ menuChoice, playlist, duration, sessionId, trackType = 'track', text, url, token }) => ({
        url: `TrackCreate`,
        method: 'POST',
        body: {
          token: getTurnstileToken(token),
          params: {
            'menu-choice': menuChoice,
            playlist,
            duration,
            parent_session_id: sessionId,
            track_type: trackType,
            text,
            url
          }
        }
      }),
      invalidatesTags: ['Track']
    }),
    createTrackWithFormData: builder.mutation<void, FormData>({
      query: (payload) => ({
        url: `TrackCreate`,
        method: 'POST',
        body: payload,
        formData:true
      }),
      invalidatesTags: ['Track']
    })
  })
});

export const {
  useGetGeneratedTrackListQuery,
  useRemoveTrackByIdMutation,
  useCreateTrackMutation,
  useCreateTrackWithFormDataMutation,
  useEditGeneratedTrackNameMutation,
  endpoints,
  reducer,
  middleware
} = trackListApi;
