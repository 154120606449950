import axios from 'axios';
import { mapPricingInfoDtoToPricingInfo } from 'mappers/pricing-info-dto-to-pricing-info';
import { SubscriptionPeriod } from 'interfaces';

export type PriceItemType = 'single_purchase' | 'subscription';
type PriceOption = {
  feature?: string;
  title: string;
  value: 0 | 1;
};

export type PriceInfoDto = {
  subscription: Array<{
    name: string;
    license_type: string;
    page_description: string;
    popup_description: string;
    feature: string;
    price_title: string;
    type: PriceItemType;
    options?: PriceOption[];
    flag_unlocked: 0 | 1;
    flag_active: 0 | 1;
    flag_canceled?: 0 | 1;
    sub_exp_date?: number;
    email?: string;
    flag_free: 0 | 1;
    flag_legacy: 0 | 1;
    period: SubscriptionPeriod;
    price: number;
    stripe_price_id: string;
    cnt_limit: number;
  }>;
  track: Array<{
    name: string;
    license_type: string;
    page_description: string;
    popup_description: string;
    price_title: string;
    feature: string;
    type: PriceItemType;
    options?: PriceOption[];
    flag_unlocked: 0 | 1;
    flag_active: 0 | 1;
    flag_canceled?: 0 | 1;
    sub_exp_date?: number;
    email?: string;
    flag_free: 0 | 1;
    flag_legacy: 0 | 1;
    price: number;
    stripe_price_id: string;
    cnt_limit: number;
  }>;
};

export const priceInfo = (lang: string) =>
  axios
    .get<{ data: PriceInfoDto }>(`/v1/StripePricing?method=StripePricing&lang=${lang}`)
    .then((response) => {
      const result = response && response.data && response.data.data;

      return mapPricingInfoDtoToPricingInfo(result);
    });
