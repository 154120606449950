import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_URL } from 'constants/api';

export const paymentsApi = createApi({
  reducerPath: 'payments',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  tagTypes: ['Payments'],
  endpoints: (build) => ({
    getPayments: build.query<any, void>({
      query: () => '/StripePaymentMethods',
      transformResponse: (result: any) => {
        return result?.data;
      }
    }),
    getCharges: build.query<any, void>({
      query: () => '/StripeAllCharges?limit=100',
      transformResponse: (result: any) => {
        return result?.data;
      }
    }),
    getSubscriptions: build.query<any, void>({
      query: () => '/StripeSubscriptions',
      transformResponse: (result: any) => {
        return result?.data;
      }
    }),
    getChangePaymentMethod: build.query<any, void>({
      query: () => '/StripeBillingPortal',
      transformResponse: (result: any) => {
        return result;
      }
    })
  })
});

export const {
  useGetPaymentsQuery,
  useGetSubscriptionsQuery,
  useGetChargesQuery,
  useGetChangePaymentMethodQuery,
  reducer,
  middleware,
  util: {
    resetApiState: resetPaymentsApiState
  }
} = paymentsApi;
