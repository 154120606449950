export type PriceOption = {
  title: string;
  value: boolean;
  feature?: string;
}

export enum PriceType {
  SINGLE_PURCHASE = 'track',
  SUBSCRIPTION = 'subscription'
}

export enum SubscriptionPeriod {
  Monthly = 'monthly',
  Annual = 'annual',
}

export type ISubscriptionPricing = {
  name: string;
  licenseType: string;
  pageDescription: string;
  popupDescription: string;
  priceTitle: string;
  type: PriceType.SUBSCRIPTION;
  feature: string;
  options?: PriceOption[];
  period: SubscriptionPeriod;
  price: number;
  priceId: string;
  currentTier: boolean;
  isActive: boolean;
  isCanceled: boolean;
  expDate: number;
  email?: string;
  isFree: boolean;
  isLegacy: boolean;
  isBlackFriday?: boolean;
  cntLimit?: number;
};

export type ISinglePurchasePricing = {
  name: string;
  licenseType: string;
  pageDescription: string;
  popupDescription: string;
  priceTitle: string;
  feature:string;
  type: PriceType.SINGLE_PURCHASE;
  price: number;
  priceId: string;
  options?: PriceOption[];
  currentTier: boolean;
  isActive: boolean;
  isCanceled: boolean;
  expDate: number;
  email?: string;
  isFree: boolean;
  isLegacy: boolean;
  isBlackFriday?: boolean;
  cntLimit?: number;
};

export type IPricingData = {
  subscription: ISubscriptionPricing[];
  singlePurchase: ISinglePurchasePricing[];
  blackFriday: ISubscriptionPricing[];
};

export interface IPricingInfo {
  isLoading: boolean;
  isLoadedForLoggedInUser:boolean;
  data: IPricingData
}
