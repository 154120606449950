import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { STATIC_API_URL } from 'constants/api';

export type New = {
  date: number;
  images: string[];
  link: string;
  main_description: string;
  mobile_images: string[];
  preview_description: string;
  title: string;
};

export const whatsNewApi = createApi({
  reducerPath: 'whatsNew',
  tagTypes: ['whatsNew'],
  baseQuery: fetchBaseQuery({
    baseUrl: STATIC_API_URL
  }),
  endpoints: (builder) => ({
    getWhatsNew: builder.query<New[], void>({
      query: () => ({
        url: `/other/whatsnew.json?t=${+new Date()}`
      }),
      transformResponse: (result: New[]) => result,
      providesTags: ['whatsNew']
    })
  })
});

export const { useGetWhatsNewQuery, endpoints, reducer, middleware } = whatsNewApi;
