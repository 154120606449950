import { PriceInfoDto } from 'api/price-info';
import { IPricingData, PriceType, SubscriptionPeriod } from 'interfaces/pricing-info';


export function mapPricingInfoDtoToPricingInfo(prices: PriceInfoDto): IPricingData {
  const subscription = prices.subscription.map((item) => {
    return {
      name: item.name,
      licenseType: item.license_type,
      pageDescription: item.page_description,
      popupDescription: item.popup_description,
      feature: item.feature,
      priceTitle: item.price_title,
      type: PriceType.SUBSCRIPTION as const,
      options: item.options?.map((option) => ({
        title: option.title,
        value: !!option.value,
        feature: option.feature
      })),
      price: item.price,
      priceId: item.stripe_price_id,
      period: item.period,
      currentTier: !!item.flag_unlocked,
      isActive: !!item.flag_active,
      isCanceled: !!item.flag_canceled,
      expDate: item.sub_exp_date,
      email: item.email,
      isLegacy: !!item.flag_legacy,
      isFree: !!item.flag_free,
      cntLimit: item.cnt_limit
    };
  });

  const annualAmbassador = subscription.find((item) => item.name.toLowerCase() === 'ambassador');

  return {
    subscription: annualAmbassador
      ? [
          {
            ...annualAmbassador,
            period: SubscriptionPeriod.Annual
          },
          ...subscription
        ]
      : subscription,
    singlePurchase: prices.track.map((item) => ({
      name: item.name,
      licenseType: item.license_type,
      pageDescription: item.page_description,
      popupDescription: item.popup_description,
      priceTitle: item.price_title,
      feature: item.feature,
      type: PriceType.SINGLE_PURCHASE,
      options: item.options?.map((option) => ({
        title: option.title,
        value: !!option.value,
        feature: option.feature
      })),
      price: item.price,
      priceId: item.stripe_price_id,
      currentTier: !!item.flag_unlocked,
      isActive: !!item.flag_active,
      isCanceled: !!item.flag_canceled,
      expDate: item.sub_exp_date,
      email: item.email,
      isLegacy: !!item.flag_legacy,
      isFree: !!item.flag_free,
      cntLimit: item.cnt_limit
    })),
    blackFriday: prices.subscription
      .map((item) => {
        return {
          name: item.name,
          licenseType: item.license_type,
          pageDescription: item.page_description,
          popupDescription: item.popup_description,
          feature: item.feature,
          priceTitle: item.price_title,
          type: PriceType.SUBSCRIPTION as const,
          options: item.options?.map((option) => ({
            title: option.title,
            value: !!option.value,
            feature: option.feature
          })),
          price: item.price,
          priceId: item.stripe_price_id,
          period: item.period,
          currentTier: !!item.flag_unlocked,
          isActive: !!item.flag_active,
          isCanceled: !!item.flag_canceled,
          expDate: item.sub_exp_date,
          email: item.email,
          isLegacy: !!item.flag_legacy,
          isFree: !!item.flag_free,
          isBlackFriday: true
        };
      })
      .filter(
        (item) =>
          item.name === 'Creator' ||
          item.name === 'Pro' ||
          item.name === 'Business' ||
          item.name === 'Ambassador'
      )
  };
}
