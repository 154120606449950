import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import loadable from '@loadable/component';
import { ErrorBoundary } from 'layout/components/error-boundary';
import './App.scss';
import LogoSvg from './assets/img/logo-header.svg';

const MainPage = loadable(() => import('./pages/main'));
const RenderPage = loadable(() => import('./pages/render'));
const AboutPage = loadable(() => import('./pages/about'));
const ContactPage = loadable(() => import('./pages/ContactPage'));
const NotFoundPage = loadable(() => import('./pages/sessions/404'));
const ProductsPage = loadable(() => import('./pages/products'));
const LpPage = loadable(() => import('./pages/lp'));
const SessionsPage = loadable(() => import('./pages/sessions'));
const RenderDocumentsPage = loadable(() => import('./pages/render-documents'));
const WebsiteDocumentsPage = loadable(() => import('./pages/website-documents'));

const App = () => {

  return (
    <ErrorBoundary>
      <Switch>
        <Route path='/' exact component={MainPage} />
        <Route path='/about-us' exact component={AboutPage} />
        <Route path='/contact' exact component={ContactPage} />
        <Route path='/render/sign-in' component={SessionsPage} />
        <Route path='/render/sign-up' component={SessionsPage} />
        <Route path='/render/forget' component={SessionsPage} />
        <Route path='/render/confirm' component={SessionsPage} />
        <Route path='/render/docs/:id?' component={RenderDocumentsPage} />
        <Route path='/docs/:id?' component={WebsiteDocumentsPage} />
        <Route path='/render' component={RenderPage} />
        <Route path='/products' component={ProductsPage} />
        <Route path='/lp/:id' component={LpPage} />
        <Route path='/page-not-found' component={NotFoundPage} />
        <Route path='*' component={NotFoundPage} />
      </Switch>
    </ErrorBoundary>
  );
};

const Maintenance = () => {
  return (
    <div style={{ textAlign: 'center', paddingTop: 100 }}>
      <Helmet>
        <title>
          Mubert - Thousands of Staff-Picked Royalty-Free Music Tracks for Streaming, Videos,
          Podcasts, Commercial Use and Online Content
        </title>
      </Helmet>
      <img src={LogoSvg} alt='logo' />
      <h3 style={{ textAlign: 'center' }}>
        Mubert is currently down for maintenance.
        <br />
        We'll be back in a few hours with some improvements ⚡
      </h3>
    </div>
  );
};

const isMaintenance =
  typeof window === 'object'
    ? window.__IS_MAINTENANCE__ === true
    : process.env.IS_MAINTENANCE === 'true';

const AppOrMaintenance = isMaintenance ? Maintenance : App;

export default AppOrMaintenance;
