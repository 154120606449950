import { IModals } from 'interfaces';
import { HIDE_MODAL, SHOW_MODAL, ModalActionTypes } from './types';

const initialState = {
  isVisible: false,
  name: '',
  showMask: true
};

export const modalsReducer = (
  state: IModals = initialState,
  action: ModalActionTypes
): IModals => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        name: action.payload.name,
        onClose: action.payload.onClose,
        onComplete: action.payload.onComplete,
        additionalProps: action.payload.props,
        small: action.payload.small,
        extraSmall: action.payload.extraSmall,
        width: action.payload.width,
        large: action.payload.large,
        big: action.payload.big,
        fullHeight: action.payload.fullHeight,
        showMask: action.payload.showMask === undefined
          ? true
          : action.payload.showMask,
        isVisible: true,
        closable: action.payload.closable
      };
    case HIDE_MODAL:
      return {
        ...state,
        closable: true,
        showMask: true,
        onComplete: undefined,
        isVisible: false
      };
    default:
      return state;
  }
};
